import React from 'react'
import NewPasswordComponent from '../features/user/NewPasswordComponent'

function NewPassword() {
  return (
    <div>
      <NewPasswordComponent/>
    </div>
  )
}

export default NewPassword
