import { USER_ROLE } from "./enums";

const checkAuth = () => {
	const TOKEN = localStorage.getItem("token");
	const ROLE = localStorage.getItem("role");
	const status = localStorage.getItem("Status");
	const user = USER_ROLE[ROLE];

	const PUBLIC_ROUTES = [
		"login",
		"forgot-password",
		"register",
		"EmailVerification",
		"new-password",
		"emailverification-permotion",
		"landing",
	];

	const userType = {
		SEEKER: "seeker",
		EMPLOYER: "employer",
	};

	const isPublicPage = PUBLIC_ROUTES.some((r) => window.location.href.includes(r));
	const EmployeeInactiveStatus = ["draft", "pending", "rejected"];
	console.log("sdsf---1", window.location.origin);
	if (TOKEN && !user) {
		// Clear local storage and redirect to login if user role is not recognized
		localStorage.clear();
		window.location.href = "/login";
	} else {
		// Return token, user, and status if authenticated
		return { token: TOKEN, user: user, status: status };
	}
};

export default checkAuth;
